import { DateTime } from "luxon"

/**
 * @param {import("../Types").MealPlan} mealPlan
 * @param {number} day
 * @param {number} meal
 * @param {string[]} meals
 * @returns {import("../Types").Meal | null}
 */
export function findMeal(mealPlan, day, meal, meals) {
  const lowerMeals = meals.map((meal) => meal.toLocaleLowerCase())

  return (
    mealPlan.meals.find((m) => {
      return DateTime.fromISO(m.date).weekday === day + 1 && m.time === lowerMeals[meal]
    }) || null
  )
}
