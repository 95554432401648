import { Fab, Grid, Typography } from "@material-ui/core"
import { DateTime } from "luxon"
import React from "react"
import ReactMaterialComponentBase from "../../Shared/ReactMaterialComponentBase"
import { ViewMealPlan as ReactViewMealPlan } from "../React/ViewMealPlan"
import { Edit as EditIcon } from "@material-ui/icons"
import s1 from "react-grid-layout/css/styles.css"

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
const meals = ["Lunch", "Tea"]

export default class CurrentMealPlan extends ReactMaterialComponentBase {
  constructor() {
    super()

    /** @type {import("../Types").MealPlan} */
    this.mealPlan = { meals: [], startDate: DateTime.local().startOf("week").toJSDate() }

    this.jsxRootComponent = () => (
      <Grid container spacing={2}>
        {this.mealPlan ? (
          <>
            <Grid item xs={12}>
              <ReactViewMealPlan editable={false} mealPlan={this.mealPlan} days={days} meals={meals} />
            </Grid>
            <Grid item xs={12}>
              <Fab
                color="primary"
                onClick={() => {
                  this.dispatchEvent(
                    new CustomEvent("edit-mealplan", { detail: this.mealPlan, bubbles: true, composed: true })
                  )
                }}
              >
                <EditIcon />
              </Fab>
            </Grid>
          </>
        ) : (
          <Typography>No Meal Plan This Week</Typography>
        )}
      </Grid>
    )
  }

  connectedCallback() {
    super.connectedCallback() && super.connectedCallback()

    const style = document.createElement("style")
    style.type = "text/css"
    style.appendChild(document.createTextNode(s1))

    this.shadow.appendChild(style)

    this.getMealPlan()
  }

  async getMealPlan() {
    const target = this.getAttribute("target")

    try {
      const token = await window.authorise()

      const mealPlanResponse = await fetch(target, {
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      })

      const mealPlan = await mealPlanResponse.json()

      this.mealPlan = mealPlan

      this.render()
    } catch (error) {
      this.onError && this.onError(error)
    }
  }
}

customElements.define("current-meal-plan", CurrentMealPlan)
