import {
  Fab,
  Grid,
  Typography,
  useMediaQuery,
  Card,
  CardContent,
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  TextField,
  FormGroup,
  FormControl,
  IconButton,
  Box,
  Tabs,
  Tab,
} from "@material-ui/core"
import React, { createRef, useCallback, useContext, useEffect, useRef, useState } from "react"
import { findMeal } from "../Shared/FindMeal"
import { Close, Cancel } from "@material-ui/icons"
import { ShadowContext } from "../../Shared/ShadowContext"
import { RecipeImage } from "./Images"

function useWidth(elementRef) {
  const [width, setWidth] = useState(null)

  const updateWidth = useCallback(() => {
    if (elementRef && elementRef.current) {
      const { width } = elementRef.current.getBoundingClientRect()
      setWidth(width)
    }
  }, [elementRef])

  useEffect(() => {
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => {
      window.removeEventListener("resize", updateWidth)
    }
  }, [updateWidth])

  return [width]
}

/**
 * @param {{ recipe?: import("../Types").Recipe, reason: string }} recipeOrReason
 */
const RecipeOrReason = ({ recipe, reason }) => {
  const [open, setOpen] = useState(false)

  const { shadowRoot } = useContext(ShadowContext)

  const style =
    reason || !recipe.id
      ? { flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }
      : { padding: 0, flex: 0 }

  return (
    <>
      {recipe ? (
        <>
          <RecipeCardDialog shadowRoot={shadowRoot} recipe={recipe} open={open} onClose={() => setOpen(false)} />
          <CardContent style={style} onClick={() => setOpen(true)}>
            <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
              {!recipe.id ? (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography style={{ fontWeight: "bold" }}>Custom Recipe</Typography>
                </Grid>
              ) : null}
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography>{recipe.name}</Typography>
              </Grid>
            </Grid>
          </CardContent>
          <RecipeImage minHeight={120} recipe={recipe} />
        </>
      ) : null}

      {reason ? (
        <CardContent style={style}>
          <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography style={{ fontWeight: "bold" }}>Meal Not Needed</Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography>{reason}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      ) : null}
    </>
  )
}

const RecipeCardDialog = (props) => {
  const { onClose, open, shadowRoot, recipe } = props

  return (
    <Dialog
      fullWidth
      container={() => shadowRoot.querySelector("#container")}
      onClose={onClose}
      aria-labelledby="recipe-dialog-title"
      open={open}
      disableEnforceFocus={true}
      PaperProps={{
        style: {
          width: "100%",
          overflowX: "hidden",
        },
      }}
    >
      <view-recipe target={`./mealplan/recipe/${recipe.id}`}></view-recipe>
    </Dialog>
  )
}

const dayColours = ["#4caf50", "#f44336", "#03a9f4", "#9c27b0", "#ff5722", "#00bcd4", "#ffc107"]

/**
 * @param {{ mealPlan: import("../Types").MealPlan; editable: boolean; addMeal?: (mealInDay: number, dayOfWeek: number) => void; removeMeal?: (mealInDay: number, dayOfWeek: number) => void; days: string[]; meals: string[] }} props
 * @returns {JSX.Element}
 */
export const ViewMealPlan = (props) => {
  const { addMeal = () => {}, removeMeal = () => {}, mealPlan, editable } = props

  const ref = createRef()
  const [width] = useWidth(ref)
  const [dialogType, setDialogType] = useState("meal")
  const [dialogOpen, setDialogOpen] = useState(false)
  const desktop = useMediaQuery((theme) => theme.breakpoints.up("lg"))

  const daysOfWeek = props.days
  const mealsOfDay = props.meals

  const itemWidth = width / 7

  const style = desktop && width ? { width: itemWidth, maxWidth: itemWidth, flexBasis: itemWidth } : {}

  return (
    <>
      <Grid id="container" ref={ref} container spacing={2}>
        {daysOfWeek.map((day, dayIndex) => {
          return (
            <Grid key={dayIndex} container spacing={2} item xs={12} sm={6} md={3} lg={1} style={style}>
              {mealsOfDay.map((meal, mealIndex) => {
                const mealSlot = findMeal(mealPlan, dayIndex, mealIndex, mealsOfDay)

                const { recipe, reason } = mealSlot || {}

                const canRemove = editable && (recipe || reason) && removeMeal

                return (
                  <Grid key={mealIndex} item xs={12}>
                    <Card
                      style={{ height: 300, display: "flex", flexDirection: "column", justifyContent: "space-between" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          maxWidth: "100%",
                          justifyContent: "space-between",
                          overflow: "hidden",
                          width: "100%",
                          alignItems: "center",
                          color: "white",
                          fontWeight: "bold",
                          backgroundColor: dayColours[dayIndex],
                          padding: "20px 15px",
                          boxSizing: "border-box",
                          height: 60,
                          minHeight: 60,
                        }}
                      >
                        <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                          <Typography component="span" variant="h5">{`${day} ${meal}`}</Typography>
                        </div>
                        {canRemove ? (
                          <IconButton
                            style={{ color: "white", padding: 0 }}
                            onClick={() => removeMeal(mealIndex, dayIndex)}
                          >
                            <Cancel />
                          </IconButton>
                        ) : null}
                      </div>

                      {recipe || reason ? (
                        <RecipeOrReason
                          recipe={recipe}
                          reason={reason}
                          removeMeal={editable ? () => removeMeal(mealIndex, dayIndex) : undefined}
                        />
                      ) : (
                        <CardContent style={{ display: "flex", flexDirection: "column" }}>
                          {editable ? (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ marginBottom: 48 }}
                                onClick={() => {
                                  addMeal(mealIndex, dayIndex)
                                  setDialogType("meal")
                                  setDialogOpen(true)
                                }}
                              >
                                Add Recipe
                              </Button>
                              <Button
                                variant="contained"
                                color="secondary"
                                style={{ marginBottom: 48 }}
                                onClick={() => {
                                  addMeal(mealIndex, dayIndex)
                                  setDialogType("reason")
                                  setDialogOpen(true)
                                }}
                              >
                                Meal not needed
                              </Button>
                            </>
                          ) : null}
                        </CardContent>
                      )}
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
          )
        })}
      </Grid>
      <MealPlanDialog
        dialogType={dialogType}
        shadowRoot={props.shadowRoot}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  )
}

function MealDialogContent() {
  const ref = useRef(/** @type {HTMLDivElement | null} */ (null))
  const [adhocMeal, setAdhocMeal] = useState("")
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function selectCustom(adhocMeal) {
    if (!ref.current) {
      return
    }

    /** @type {import("../Types").Recipe} */
    const customRecipe = { name: adhocMeal }

    ref.current.dispatchEvent(
      new CustomEvent("recipe-selected", {
        composed: true,
        bubbles: true,
        detail: {
          recipe: customRecipe,
        },
      })
    )
  }

  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Search Recipes" value={0} />
        <Tab label="Search By Book" value={1} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <Grid container spacing={2} ref={ref} style={{ margin: 0, width: "100%" }}>
          <Grid item xs={12}>
            <FormGroup row={true} style={{ alignItems: "baseline" }}>
              <FormControl margin="dense" style={{ flex: 1, paddingRight: 10 }}>
                <TextField
                  variant="outlined"
                  label="Custom Meal"
                  helperText="You can manually enter a meal if it doesn't have a recipe"
                  value={adhocMeal}
                  onChange={(event) => setAdhocMeal(event.target.value)}
                />
              </FormControl>
              <FormControl margin="dense">
                <Fab color="primary" variant="extended" onClick={() => selectCustom(adhocMeal)}>
                  Select
                </Fab>
              </FormControl>
            </FormGroup>
          </Grid>
        </Grid>
        <search-recipe target="./mealplan/recipe/search"></search-recipe>
        <spotlight-recipe target="./mealplan/recipe" spotlight="highestrated"></spotlight-recipe>
        <spotlight-recipe target="./mealplan/recipe" spotlight="mostused"></spotlight-recipe>
        <spotlight-recipe target="./mealplan/recipe" spotlight="recentlyused"></spotlight-recipe>
        <spotlight-recipe target="./mealplan/recipe" spotlight="newest"></spotlight-recipe>
        <spotlight-recipe target="./mealplan/recipe" spotlight="notrecentlyused"></spotlight-recipe>
        <spotlight-recipe target="./mealplan/recipe" spotlight="leastused"></spotlight-recipe>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={2} ref={ref} style={{ margin: 0, width: "100%" }}>
          <Grid item xs={12}>
            <list-book target={`./mealplan/book`} mode="inline"></list-book>
          </Grid>
        </Grid>
      </TabPanel>
    </>
  )
}

function ReasonDialogContent() {
  const [reason, setReason] = useState("")
  const ref = useRef(/** @type {HTMLDivElement | null} */ (null))

  function selectReason(reason) {
    if (!ref.current) {
      return
    }

    ref.current.dispatchEvent(
      new CustomEvent("reason-selected", {
        composed: true,
        bubbles: true,
        detail: {
          reason,
        },
      })
    )
  }

  return (
    <Grid container spacing={2} ref={ref}>
      <Grid item xs={12}>
        <FormGroup row={true} style={{ alignItems: "baseline" }}>
          <FormControl margin="dense" style={{ flex: 1, paddingRight: 10 }}>
            <TextField
              variant="outlined"
              label="Reason"
              fullWidth={true}
              helperText="Enter the reason the meal isn't required"
              value={reason}
              onChange={(event) => setReason(event.target.value)}
            />
          </FormControl>
          <FormControl margin="dense">
            <Fab color="primary" variant="extended" onClick={() => selectReason(reason)}>
              Set Reason
            </Fab>
          </FormControl>
        </FormGroup>
      </Grid>
    </Grid>
  )
}

function MealPlanDialog(props) {
  const { onClose, open, shadowRoot, dialogType } = props

  useEffect(() => {
    function recipeSelected() {
      onClose()
    }

    document.addEventListener("reason-selected", recipeSelected)
    document.addEventListener("recipe-selected", recipeSelected)

    return () => {
      document.removeEventListener("reason-selected", recipeSelected)
      document.removeEventListener("recipe-selected", recipeSelected)
    }
  }, [])

  return (
    <Dialog
      fullWidth
      container={() => shadowRoot.querySelector("#container")}
      maxWidth={dialogType === "meal" ? "xl" : "sm"}
      onClose={onClose}
      aria-labelledby="search-recipe-title"
      open={open}
      disableEnforceFocus={true}
      PaperProps={{
        style: {
          width: "100%",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle
        id="search-recipe-title"
        style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between" }}
      >
        Search Recipes
        <IconButton onClick={() => onClose()}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>{dialogType === "meal" ? <MealDialogContent /> : <ReasonDialogContent />}</DialogContent>
    </Dialog>
  )
}
